import { Flex, Icon, Box, Link, Image, Text } from '@chakra-ui/react'

import React from 'react'
import { FaApple } from 'react-icons/fa'
import { colors } from 'styles/foundations'

export default function GoogleAuth() {
  return (
    <Flex
      align={'center'}
      justifyContent={{ lg: 'flex-start', base: 'center' }}
      gap="1rem"
      w={'full'}>
      <Link
        href="https://play.google.com/store/apps/details?id=com.lint.finance.lint&pli=1"
        target={'_blank'}
        cursor={'pointer'}
        w={{ sm: '180px', base: 'full' }}>
        <Flex
          padding={{ sm: '2rem', base: '1rem' }}
          borderRadius={'0.8rem'}
          border={'1px solid black'}
          color={colors.brand.darkColors[0]}
          h="56px"
          w={'full'}
          align="center"
          bg={colors.brand.white}
          gap={{ sm: 4, base: 2 }}>
          <Image w={{ md: '30px', base: '24px' }} src="/icons/google-play.svg" />
          <Box>
            <Text fontSize={{ sm: '16px', base: '12px' }}>Get it on</Text>
            <Text fontSize={{ sm: '16px', base: '12px' }} fontWeight={'semibold'}>
              {' '}
              Google Play
            </Text>
          </Box>
        </Flex>
      </Link>

      <Link
        w={{ sm: '180px', base: 'full' }}
        href="https://apps.apple.com/ng/app/lint/id6469200392"
        target={'_blank'}
        cursor={'pointer'}>
        <Flex
          padding={'2rem'}
          borderRadius={'0.8rem'}
          border={'1px solid black'}
          h="56px"
          w={'full'}
          align="center"
          bg={colors.brand.white}
          gap={{ sm: 4, base: 2 }}>
          <Icon as={FaApple} boxSize={{ md: '14', base: '12' }} />
          <Box>
            <Text fontSize={{ sm: '16px', base: '12px' }}>Get it on</Text>
            <Text fontSize={{ sm: '16px', base: '12px' }} fontWeight={'semibold'}>
              App Store
            </Text>
          </Box>
        </Flex>
      </Link>
    </Flex>
  )
}
