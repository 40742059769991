import { Box, Flex, Text, Link, Icon } from '@chakra-ui/react'
import { FaApple } from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { colors } from 'styles/foundations'
import './style/styles.css'

import Image from 'next/image'
import { useMediaQuery } from 'react-responsive'

export const Hero = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 500,
      easing: 'ease-in-out',
      delay: 100,
      once: false
    })
  }, [])

  return (
    <Box
      w={{ xl: '1300px', base: 'full' }}
      pt={{ lg: 80, base: 40 }}
      px={{ base: '2rem', md: '4rem', xl: '6rem' }}
      mx="auto">
      <Box
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        gap={isTabletOrMobile ? 20 : 0}
        justifyContent={'space-between'}
        alignItems={'center'}
        w="full">
        <Box
          display={'flex'}
          gap={{ md: '3rem', base: '2rem' }}
          flexDirection={'column'}
          w={{ base: 'full', md: '70%', lg: '50%' }}>
          <Box lineHeight={'shorter'}>
            <h1 className="section-header text-center">
              Take charge of <br style={{ display: isTabletOrMobile ? 'none' : 'flex' }} /> your
              finances with{' '}
              <span className="auto-class" style={{ fontWeight: 'lighter', fontStyle: 'italic' }}>
                Automated payment plans
              </span>{' '}
            </h1>
          </Box>{' '}
          <Text
            textAlign={{ base: 'center', lg: 'left' }}
            mx={isTabletOrMobile ? 'auto' : '0'}
            fontSize={{ base: '14px', md: '18px' }}
            w="full">
            Lint helps you optimize budgets, save up for bills, automate payments and gain
            actionable insights on your financial health.
          </Text>
          <Flex
            align={'center'}
            justifyContent={{ base: 'center', lg: 'start' }}
            gap="1rem"
            w={'full'}>
            <Link
              href="https://play.google.com/store/apps/details?id=com.lint.finance.lint&pli=1"
              target={'_blank'}
              cursor={'pointer'}
              w={{ sm: '180px', base: 'full' }}>
              <Flex
                padding={{ md: '2rem', base: '1.5rem' }}
                borderRadius={'0.8rem'}
                border={'1px solid black'}
                color={colors.brand.white}
                h="56px"
                w={'full'}
                align="center"
                justifyContent={'center'}
                bg={colors.brand.black}
                gap={{ sm: 4, base: 2 }}>
                <Image width={24} height={24} alt="google" src="/icons/google-play.svg" />
                <Box>
                  <Text fontSize={{ sm: '16px', base: '12px' }}>Get it on</Text>
                  <Text fontSize={{ sm: '16px', base: '12px' }} fontWeight={'semibold'}>
                    {' '}
                    Google Play
                  </Text>
                </Box>
              </Flex>
            </Link>

            <Link
              w={{ sm: '180px', base: 'full' }}
              href="https://apps.apple.com/ng/app/lint/id6469200392"
              target={'_blank'}
              cursor={'pointer'}>
              <Flex
                padding={{ sm: '2rem', base: '1rem' }}
                borderRadius={'0.8rem'}
                border={'1px solid black'}
                color="black"
                h="56px"
                w={'full'}
                align="center"
                justifyContent={'center'}
                gap={{ sm: 4, base: 2 }}>
                <Icon as={FaApple} boxSize={{ md: '14', base: '12' }} />
                <Box>
                  <Text fontSize={{ sm: '16px', base: '12px' }}>Get it on</Text>
                  <Text fontSize={{ sm: '16px', base: '12px' }} fontWeight={'semibold'}>
                    App Store
                  </Text>
                </Box>
              </Flex>
            </Link>
          </Flex>
        </Box>

        <Box
          w={{ base: 'full', lg: '50%' }}
          display="flex"
          justifyContent={'center'}
          pos={{ base: 'inherit', lg: 'absolute' }}
          right={0}>
          <Image
            src="/images/home/home.png"
            width={770}
            height={770}
            alt="dashboard"
            objectFit="contain"
          />
        </Box>
      </Box>
    </Box>
  )
}
