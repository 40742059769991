import FirstComponent from './first-component'
import SecondComponent from './second-component'
import ThirdComponent from './thrid-component'
import GoogleAuth from './google-auth'
import FourthComponent from './fourth-component'
import FifthComponent from './fifth-component'
import SixComponent from './six-component'
import SeventhComponent from './seventh-component'
import EightComponent from './eight-component'
import MobileFeatured from './MobileFeatured'
export {
  FirstComponent,
  SecondComponent,
  ThirdComponent,
  GoogleAuth,
  FourthComponent,
  FifthComponent,
  SixComponent,
  SeventhComponent,
  EightComponent,
  MobileFeatured
}
