import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'styles/foundations'
import { IoPaperPlaneOutline } from 'react-icons/io5'
import { TbWorld } from 'react-icons/tb'
import { IoWalletOutline } from 'react-icons/io5'
import { useMediaQuery } from 'react-responsive'

export default function FirstComponent() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const data = [
    {
      id: 1,
      title: 'Lock away funds to automate your money commitments',
      icon: IoWalletOutline
    },
    {
      id: 2,
      title: 'Get personalised tips to improve your finances',
      icon: TbWorld
    },
    {
      id: 3,
      title: 'Securely disburse recurring payments ',
      icon: IoPaperPlaneOutline
    }
  ]
  return (
    <Box bg={colors.brand['surface/dim']}>
      <Box
        h="100px"
        display={{ md: 'flex', base: 'none' }}
        mt={isTabletOrMobile ? 40 : 80}
        w="full">
        <Flex
          justifyContent={'space-between'}
          alignItems="center"
          w={{ xl: '1300px', base: 'full' }}
          mx="auto"
          gap={2}
          px={{ base: '2rem', md: '4rem', xl: '6rem' }}>
          {data.map((item) => (
            <Box key={item.id}>
              <Flex
                w="40px"
                h="40px"
                borderRadius={'full'}
                justifyContent={'center'}
                alignItems="center"
                float={'left'}
                mr={4}
                bg={colors.brand.darkColors[0]}>
                <Icon as={item.icon} color={colors.brand.white} boxSize="24px" />
              </Flex>
              <Text fontWeight={'medium'} w={'full'}>
                {item.title}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
