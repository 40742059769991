import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

export const FEATURED_MOBILE = [
  {
    image: '/images/home/create.png',
    title: 'Create a Plan',
    description: 'Create bill plans for your personal, home or business bills.'
  },

  {
    image: '/images/home/bills.png',
    title: 'Add your bills',
    description:
      'Add bills, subscriptions and scheduled bank transfers to your family, contractors or staff.'
  },
  {
    image: '/images/home/choose.png',
    title: 'Fund your plan',
    description:
      'Save for your bills by funding your plan or setup auto-top-up at convenient intervals.'
  },
  {
    image: '/images/home/yourplan.png',
    title: 'Relax & monitor',
    description:
      "Lint will automatically process your payments from your bill savings whenever they're due."
  }
]
const MobileFeatured = () => {
  return (
    <Box px={6} w={{ sm: '450px', md: '600px' }} mx="auto">
      <Heading
        as="h2"
        mt={20}
        fontSize={{ md: '4rem', base: '32px' }}
        fontWeight={700}
        lineHeight="128%"
        letterSpacing="-0.01em"
        textAlign={'center'}>
        How it works!
      </Heading>
      <AnimatePresence>
        <Box mt={6}>
          {FEATURED_MOBILE.map((item, key) => {
            return (
              <>
                <Box mt={16}>
                  <Heading fontSize={{ base: '20px', md: '30px' }} color={'#301446'}>
                    {item.title}
                  </Heading>
                  <Text
                    fontSize={{ base: '16px', md: '20px' }}
                    lineHeight={{ base: '2.5rem', md: '4rem' }}
                    color="#141226"
                    opacity="0.7"
                    mt={5}>
                    {item.description}
                  </Text>

                  <Flex
                    key={key}
                    flexDir={'column'}
                    data-aos="zoom-in"
                    data-aos-duration="200"
                    data-aos-delay="0"
                    mt={4}>
                    <Image
                      alt="feature-images"
                      key={`${item.image}`}
                      src={item.image}
                      transition={{ ease: 'anticipate' }}
                      data-aos="zoom-in"
                      data-aos-duration="200"
                      data-aos-delay="0"
                    />
                  </Flex>
                </Box>
              </>
            )
          })}
        </Box>
      </AnimatePresence>
    </Box>
  )
}

export default MobileFeatured
