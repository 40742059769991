import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../style/styles.css'

export default function EightComponent() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <Box
      w={{ xl: '1300px', base: 'full' }}
      mx="auto"
      px={{ base: '2rem', md: '4rem', lg: '6rem' }}
      pt={{ lg: 20, base: 20 }}
      data-aos="fade-up"
      data-aos-duration="1000">
      <Flex
        align={'center'}
        justifyContent="space-between"
        direction={{ lg: 'row', base: 'column' }}>
        <Flex direction={'column'} gap={8} w={{ md: '524px', base: '100%' }}>
          <Box lineHeight={'shorter'}>
            <h1 className="take-charge ">
              Dedicated virtual & physical utility cards, strictly for your bills
            </h1>
          </Box>
          <Text
            color={'#4A485F'}
            fontSize={{ base: '16px', md: '18px' }}
            textAlign={{ lg: 'start', base: 'center' }}>
            Get a utility card to lock funds for your recurring subscriptions or monthly runs for
            groceries & gas. Use your utility card on ATMs and merchant sites around the world.
          </Text>
        </Flex>

        <Image
          src="/images/card.png"
          data-aos="fade-up"
          w={{ sm: '500px', base: '100%' }}
          my={{ lg: 0, base: 8 }}
          objectFit="contain"
        />
      </Flex>
    </Box>
  )
}
