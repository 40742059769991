import { Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { colors } from 'styles/foundations'
import GoogleAuth from '../google-auth'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../style/styles.css'
import Image from 'next/image'
import { useMediaQuery } from 'react-responsive'

export default function ThirdComponent() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1220px)' })
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 500,
      easing: 'ease-in-out',
      delay: 100,
      once: false
    })
  }, [])
  return (
    <Box
      w={{ base: 'full' }}
      h="fit-content"
      mx="auto"
      mt={40}
      bg={isTabletOrMobile ? colors.brand.darkColors[0] : ''}
      px={{ base: '0rem', md: '4rem', xl: '6rem' }}
      pt={{ lg: 40, base: 40 }}>
      <Box
        px={{ base: '2rem', md: '4rem', xl: '6rem' }}
        bg={colors.brand.darkColors[0]}
        display={'flex'}
        flexDirection={{ lg: 'row', base: 'column' }}
        alignItems="center"
        justifyContent="center "
        w="full"
        gap={{ lg: 0, base: 10 }}
        pt={10}>
        <Box
          w={{ md: '517px', base: 'full' }}
          data-aos="fade-up"
          display="flex"
          flexDirection={'column'}
          gap={6}>
          <Box lineHeight={'shorter'}>
            <h1 className="take-charge " style={{ color: 'white' }}>
              Get insights into your{' '}
              <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>financial health</span>
            </h1>
          </Box>
          <Text
            color={colors.brand.white}
            w={{ md: '500px', lg: '317px', base: '100%' }}
            textAlign={{ lg: 'start', base: 'center' }}>
            Know what you’re spending on, what you should cut back on and how much you should save
            to stay on top of your bills.
          </Text>

          <GoogleAuth />
        </Box>
        <Image
          alt="expenses"
          data-aos="zoom-in"
          src="/images/expenses.png"
          width={560}
          height={540}
        />
      </Box>
    </Box>
  )
}
