import { Box, Flex, Heading } from '@chakra-ui/react'
import { useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { AnimatePresence, transform } from 'framer-motion'
import AnimatedImage from './AnimateImage'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { SingleFeatureText } from './SingleFeaturetext'
import { useMediaQuery } from 'react-responsive'

const BTN_PROPS = {
  color: 'white',
  borderRadius: 'full',
  height: '70px',
  mb: '4px',
  justifyContent: 'flex-start',
  'data-aos': 'fade-up',
  'data-aos-duration': '300',
  'data-aos-delay': '0',
  transition: 'all .25s ease',
  fontSize: '18px',
  fontWeight: 'bold',
  bgColor: 'transparent',
  style: {
    backgroundColor: 'transparent'
  }
}

const IMAGES_ARRAY = [
  '/images/home/create.png',
  '/images/home/create.png',
  '/images/home/create.png',
  '/images/home/bills.png',
  '/images/home/choose.png',
  '/images/home/choose.svg',
  '/images/home/yourplan.png',
  '/images/home/yourplan.png'
]

const FeatureSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [progress, setProgress] = useState(0)
  const { height } = useWindowDimensions()
  const transformer = transform([-1, 0, 0.18, 0.28, 0.38, 0.48, 0.6, 0.7], IMAGES_ARRAY)
  const image = transformer(progress)

  const START_ANIMATION = height <= 660 ? 0.095 : 0.11
  const RATIO = (height - 660) / (660 - 2002)
  const LINEAR_INTERPOLATION = 0.729 + (0.729 - 0.42) * RATIO
  const STANDARD = parseFloat(LINEAR_INTERPOLATION.toFixed(4))
  const [active, setActive] = useState('create-a-plan')
  return (
    <Box
      style={{
        position: 'relative',
        marginTop: 80
      }}>
      <AnimatePresence>
        <Box
          flexDir={'column'}
          height={'calc(50vh)'}
          position={progress > STANDARD ? 'absolute' : 'relative'}
          bottom={progress > STANDARD ? 'calc(25vh)' : undefined}
          transition="all 0.5s ease-in"
          left={0}
          right={0}
          px={{ base: '2rem', md: '4rem', xl: '6rem' }}>
          <Flex
            justifyContent={'space-between'}
            position={
              progress > STANDARD ? 'static' : progress > START_ANIMATION ? 'fixed' : 'unset'
            }
            height={'calc(50vh)'}
            bottom={progress > STANDARD ? 0 : 'calc(25vh)'}
            left={30}
            right={0}
            w={{ xl: '1300px', base: 'full' }}
            mx="auto">
            <Box mt={{ md: 35, base: 0 }}>
              <Heading fontSize="4em" fontWeight={700} lineHeight="128%" letterSpacing="-0.01em">
                How it works!
              </Heading>
              <Flex flexDir={'column'} {...BTN_PROPS} data-aos-duration={'200'}>
                <SingleFeatureText
                  active={active}
                  code="create-a-plan"
                  setActive={setActive}
                  text="Create a plan"
                  description="Create bill plans for your personal, home or business bills."
                  scrollTo={2200}
                />
                <SingleFeatureText
                  active={active}
                  code="add-bills"
                  setActive={setActive}
                  text="Add your payments"
                  description="Add bills, subscriptions and scheduled bank transfers to your family, contractors or staff."
                  scrollTo={2350}
                />
                <SingleFeatureText
                  active={active}
                  code="fund-plan"
                  setActive={setActive}
                  text="Fund your plan"
                  description="Save for your bills by funding your plan or setup auto-top-up at convenient intervals."
                  scrollTo={2850}
                />
                <SingleFeatureText
                  active={active}
                  code="relax"
                  setActive={setActive}
                  text="Relax & monitor"
                  description="Lint will automatically process your payments from your bill savings whenever they're due."
                  scrollTo={3050}
                />
              </Flex>
            </Box>

            <AnimatedImage
              image={image}
              progress={progress}
              STANDARD={STANDARD}
              START_ANIMATION={START_ANIMATION}
            />
          </Flex>
        </Box>
      </AnimatePresence>
      {progress > STANDARD ? <Flex flexDir={'column'} height={'calc(50vh)'} /> : null}
      <Parallax
        speed={-10}
        onProgressChange={(progress) => {
          let active = ''

          if (progress > 0.18 && progress <= 0.28) {
            active = 'add-bills'
          }

          if (progress > 0.28 && progress <= 0.48) {
            active = 'fund-plan'
          }

          if (progress > 0.48 && progress <= 0.78) {
            active = 'relax'
          }

          if (progress <= 0.18) {
            active = 'create-a-plan'
          }

          setActive(active)
          setProgress(progress)
        }}
        // onEnter={() => {}}
        // onExit={() => {}}
        style={{
          height: isTabletOrMobile ? 350 * 2 : 490 * 4
        }}></Parallax>
    </Box>
  )
}

export default FeatureSection
