import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'styles/foundations'
import Image from 'next/image'
import { BsArrowUpRight } from 'react-icons/bs'
import '../../style/styles.css'
export default function CardsComponent() {
  const data = [
    {
      id: 1,
      title: 'Automate Salaries & Stipends',
      text: 'Set up payroll once & avoid the payday stress of manually making transfers to staff or domestic workers.',
      img: '/images/cash1.png'
    },
    {
      id: 2,
      title: 'Manage your Subscriptions',
      text: 'Get a virtual utility card for subscriptions like Netflix, Mailchimp etc. No more surprise charges on your debit card.',
      img: '/images/cash2.png'
    },
    {
      id: 3,
      title: 'Schedule your Bill Payments',
      text: 'Save ahead to automate your utilities and do away with late payments, service disruptions or debt.',
      img: '/images/cash3.png'
    },
    {
      id: 4,
      title: 'Plan for Family & Loved Ones',
      text: 'Keep your family and loved ones happy while scheduling upkeep money, pocket money, tithe, and more, much more easily',
      img: '/images/cash4.png'
    }
  ]
  return (
    <Box className="card-container" mt={10}>
      <Box
        bgGradient="linear(to-r,#665BE0 60%, #42AEDF 130%)"
        h={{ md: '300px', base: 'fit-content' }}
        className="card big-card"
        borderRadius={16}
        p={{ md: 10, base: 4 }}
        display="flex"
        flexDirection={'column'}
        justifyContent="center">
        <Text
          color={colors.brand.white}
          fontSize={{ sm: '51px', base: '30px' }}
          fontWeight="extrabold">
          Create a plan for all
          <span className="finance"> financial commitments</span>
        </Text>
        <Button
          w={'200px'}
          h="12px"
          rightIcon={<BsArrowUpRight />}
          bg={colors.brand.white}
          variant=""
          mt={6}>
          Get Started
        </Button>
      </Box>
      {data.map((item) => (
        <Flex
          key={item.id}
          flexDirection={'column'}
          shadow={'lg'}
          bg={colors.brand.white}
          justifyContent={'center'}
          gap={8}
          border="1px solid #FAFAFA"
          p={8}
          borderRadius="18px"
          h={{ sm: '300px', base: 'fit-content' }}
          w="full"
          className="card">
          <Flex
            w="40px"
            h="40px"
            borderRadius={'full'}
            justifyContent={'center'}
            alignItems="center"
            bg={colors.brand.darkColors[0]}>
            <Image
              layout="fixed"
              loading="lazy"
              src={item.img}
              alt={item.title}
              width={67}
              height={67}
            />
          </Flex>
          <Text fontWeight={'bold'} fontSize={'4xl'}>
            {item.title}
          </Text>
          <Text>{item.text}</Text>
        </Flex>
      ))}
    </Box>
  )
}
