import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { colors } from 'styles/foundations'
import AOS from 'aos'
import 'aos/dist/aos.css'
import CardsComponent from './cards'
import Image from 'next/image'

export default function SecondComponent() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  const data = [
    {
      id: 1,
      title: 'small businesses',
      text: 'Streamline your financial operations and ensure timely transactions to billers, vendors & payroll from one convenient platform.',
      img: '/images/section2.svg'
    },
    {
      id: 2,
      title: 'individuals',
      text: 'Take control of your personal finances - From scheduling payments to sending money to loved ones or domestic staff.',

      img: '/images/section1.svg'
    }
  ]
  return (
    <Box
      mt={{ md: 40, base: 0 }}
      w={{ base: 'full' }}
      mx="auto"
      px={{ base: '2rem', md: '4rem', xl: '16rem' }}>
      <Box
        display={'flex'}
        flexDirection={{ md: 'row', base: 'column' }}
        justifyContent={'center'}
        alignItems="center"
        gap={6}
        mx="auto"
        data-aos="fade-up">
        {data.map((item) => (
          <Box w="full" key={item.id} bg={colors.brand.white} borderRadius="18px">
            <Box bg={colors.brand.darkColors[0]} h="30px" />
            <Flex
              flexDirection={'column'}
              shadow={'xl'}
              gap={10}
              p={8}
              mx="auto"
              h="400px"
              borderRadius="14px">
              <Image
                alt={item.title}
                layout="fixed"
                loading="lazy"
                src={item.img}
                width={160}
                height={160}
              />
              <Flex alignItems={'center'} gap={3}>
                <Text fontSize={'4xl'}>For</Text>
                <Text fontWeight={'bold'} fontSize={'4xl'}>
                  {' '}
                  {item.title}
                </Text>
              </Flex>
              <Text fontSize={{ md: '17px', base: '16px' }}>{item.text}</Text>
            </Flex>
          </Box>
        ))}
      </Box>

      <Box data-aos="fade-up" data-aos-duration="1000">
        <CardsComponent />
      </Box>
    </Box>
  )
}
